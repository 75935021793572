<template>
    <div id="tendances" class="page-wrapper page-tendances">
        <!-- Hero -->
        <header class="small-hero">
            <article class="content" v-if="pageIsLoaded">
                <h1 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.hero.surtitre }}</h1>
                <h2 class="subtitle big-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.hero.titre"></span></h2>
            </article>
            <div class="image" data-inview="revealRight" data-delay="100">
                <img v-if="pageIsLoaded" :src="content.hero.image.url" :alt="content.hero.image.titre" >
            </div>
        </header>

        <!-- Articles -->
        <section class="articles-listing site-max-width" v-if="pageIsLoaded">
            <article class="article-item" v-for="(item, i) in globals.articlesEntryShort.data" :key="item.id" data-inview="fadeInUp" :data-delay="200 * i">
                <router-link :to="item.jsonUrl" title="">
                    <img :src="item.imageSimple1.url" :alt="item.imageSimple1.titre">
                    <h3 class="small-title article-title" >{{ item.title }}</h3>
                    <p class="regular-text article-text">{{ item.description }}</p>
                    <span class="article-date">{{ moment(item.postDate.date).format('LL') }}</span>
                    <svg height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" fill="#59BBEB"/>
                        <path d="M30.7006 23.6724L35.835 28.8057L19 28.8057L19 31.1944L35.835 31.1944L30.7006 36.3277L32.3729 38L40.3717 30L32.3728 22L30.7006 23.6724Z" fill="white"/>
                    </svg>
                </router-link>
            </article>
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
    name: 'TendancesIndex',

    computed: {
        moment() {
            moment.locale(this.$route.meta.lang)
            return moment
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted() {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
